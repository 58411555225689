import React from "react";
import { Helmet } from "react-helmet";
import { getProjectRelationships } from "@bkry/bowline-redux/projects";
import { getProjectDesignAttributes } from "@bkry/bowline-redux/projectDesigns";
import { getEnv } from "@bkry/bowline-utils";
import { useSelector } from "react-redux";

const ProjectDesignLoader = () => {
  const REACT_APP_PROJECT_ID = getEnv("REACT_APP_PROJECT_ID");

  const projectRelationships = useSelector((state) =>
    getProjectRelationships(state, REACT_APP_PROJECT_ID)
  );

  const projectDesignAttributes = useSelector((state) =>
    getProjectDesignAttributes(
      state,
      projectRelationships?.project_design?.data?.id
    )
  );

  return (
    <>
      <Helmet>
        {projectDesignAttributes?.font_faces && (
          <style>{projectDesignAttributes?.font_faces}</style>
        )}
        {projectDesignAttributes?.styling_variables && (
          <style>{projectDesignAttributes?.styling_variables}</style>
        )}
      </Helmet>
      {/* TODO: why do we need this also in body and helmet?!? */}
      {projectDesignAttributes?.font_faces && (
        <style>{projectDesignAttributes?.font_faces}</style>
      )}
      {projectDesignAttributes?.styling_variables && (
        <style>{projectDesignAttributes?.styling_variables}</style>
      )}
    </>
  );
};

export default ProjectDesignLoader;
